<template>
  <v-container
    fluid
    id="table-index-container"
  >
    <v-toolbar
      v-if="!createMode"
      id="toolbar"
      dense
      elevation="0"
      style="padding: 0;"
    >
      <v-toolbar-title
        v-if="config && config.breadcrumbs && config.breadcrumbs.items && config.breadcrumbs.items.length"
      >
        <v-breadcrumbs
          :items="config.breadcrumbs.items"
          :divider="config.breadcrumbs.divider ? config.breadcrumbs.divider : '>'"
          style="padding-left: unset !important;"
        >
          <template
            #item="{ item }"
          >
            <v-breadcrumbs-item
              :class="getBreadcrumbItemClasses(item)"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      id="v-card"
      flat
    >
      <v-card-text
        id="v-card-text"
      >
        <v-row style="margin: 0; overflow: auto;">
          <v-col style="display: flex; flex-direction: column; padding: 0;">
            <template
              v-if="createMode"
            >
              <tabs-layout
                :config="createConfig"
                :create-mode="createMode"
                @reset-create-mode="createMode = false"
              />
            </template>
            <template
              v-else
            >
              <table-grid-content
                :config="config && config.tableConfig ? config.tableConfig : {}"
                :show-cancel-button="false"
                @create-mode="createMode = true"
              />
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <slot name="dynamic-content" />
  </v-container>
</template>
<script>
import { isEmpty } from 'lodash'
import TableGridContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/TableGridContent.vue'
import TabsLayout from '../view-layouts/tabs-layout/views/TabsLayout.vue'

export default {
  name: 'TableIndex',

  components: {
    TabsLayout,
    TableGridContent
  },

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    createConfig: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      createMode: null
    }
  },

  methods: {
    isEmpty,

    getBreadcrumbItemClasses (item) {
      let classes = ''

      if (this.$vuetify.breakpoint.mobile) {
        classes += 'v-breadcrumb-item--mobile'
      }
      else {
        classes += 'v-breadcrumb-item'
      }

      if (item && item.bolded) {
        classes += ' v-breadcrumb-item--bolded'
      }

      return classes
    }
  }
}
</script>

<style scoped lang="scss">
#table-index-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F3F4F6;
}

#v-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: white;
  height: 100%;
  border-radius: 16px;
}

#toolbar {
  background-color: #F3F4F6;
}

#v-card-text {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
}

.v-breadcrumb-item {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #6B7280;
}

.v-breadcrumb-item--mobile {
  font-family: 'Satoshi', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #6B7280;
}

.v-breadcrumb-item--bolded {
  color: #111827;
}
</style>
