<template>
  <table-index
    :config="tableConfig"
    :create-config="createDetailsConfig"
  >
    <template #dynamic-content>
      <change-status
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :current-status-id="changeStatusDialogConfig.current_status"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        :callback="changeStatusDialogConfig.callback"
        :close-dialog-callback="closeChangeStatusDialog"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </table-index>
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'
import ChangeStatus from '@/modules/fleet-module/components/ChangeStatus.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'
import tableConfig from '../js/main-table/tableConfig'
import createConfig from '../js/create/createConfig'

export default {
  name: 'DispositionView',

  mixins: [
    tableConfig,
    createConfig
  ],

  components: {
    InformationDialog,
    ChangeStatus,
    TableIndex
  }
}
</script>
