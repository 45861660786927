<template>
  <table-index
    :config="tableConfig"
    :create-config="createDetailsConfig"
  >
    <template #dynamic-content>
      <change-status
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :current-status="changeStatusDialogConfig.current_status"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        :callback="changeStatusDialogConfig.callback"
        :close-dialog-callback="closeChangeStatusDialog"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </table-index>
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'
import { currentDate, currentTime } from '@/global/services/helpers/dates'
import { api } from '@/global/services/api'
import ChangeStatus from '@/modules/fleet-module/components/ChangeStatus.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'
import ColumnsContent from '@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue'
import { isObject } from 'lodash'

const columnsContentComponent = ColumnsContent
export default {
  name: 'MachineOperationView',

  components: {
    InformationDialog,
    ChangeStatus,
    TableIndex
  },

  data () {
    return {
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        current_status: null,
        document_id: null,
        callback: null
      },
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      }
    }
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'travel-documents/machine-operation',
              method: 'get'
            }
          },
          rowClick: (event) => this.showMachineOperationDetails(event),
          search_and_buttons_config: {
            newClick: (event) => this.showCreateNewMachineOperation(event)
          },
          title_and_back_config: {
            title: this.$t('fleet.machine_operation')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            cellsConfig: {
              id: {
                style: {
                  textAlign: 'center'
                }
              },
              foreign_code: {
                style: {
                  textAlign: 'center'
                }
              },
              date: {
                style: {
                  textAlign: 'center'
                }
              },
              vehicle_garage_number: {
                style: {
                  textAlign: 'center'
                }
              },
              construction_site: {
                style: {
                  textAlign: 'center'
                }
              },
              section: {
                style: {
                  textAlign: 'center'
                }
              },
              cost_location: {
                style: {
                  textAlign: 'center'
                }
              },
              engineer_name: {
                style: {
                  textAlign: 'center'
                }
              },
              created_at: {
                style: {
                  textAlign: 'center'
                }
              },
              status: {
                type: 'status',
                custom_display_value: (data) => {
                  if (data && data.status && isObject(data.status)) {
                    return {
                      ...data.status,
                      ...{
                        description: data.status.description ? this.$t(data.status.description) : '-'
                      }
                    }
                  }
                },
                displayValue: 'description',
                colorObjectKey: 'color',
                textColorKey: 'text',
                backgroundColorKey: 'background',
                style: {
                  textAlign: '-webkit-center'
                },
                statusStyle: {
                  textAlign: 'center',
                  borderRadius: '8px',
                  height: '24px',
                  paddingRight: '12px',
                  paddingLeft: '12px',
                  width: 'max-content'
                }
              }
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.sr_no'),
                key: 'id',
                style: {
                  width: '100px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/machine.machine_operation_document_number'),
                key: 'foreign_code',
                style: {
                  width: '150px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.date'),
                key: 'date_time',
                style: {
                  width: '155px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.label'),
                key: 'vehicle_brand',
                style: {
                  width: '172px'
                }
              },
              {
                label: this.$t('fleet/vehicle_attribute.garage_number'),
                key: 'vehicle_garage_number',
                style: {
                  width: '172px',
                  textAlign: 'center'
                }
              },
              {
                label: this.$t('fleet/documents/machine.construction_site_drive'),
                key: 'construction_site',
                style: {
                  width: '172px',
                  textAlign: 'center'
                }
              },
              {
                label: this.$t('fleet/documents/machine.section'),
                key: 'section',
                style: {
                  width: '172px',
                  textAlign: 'center'
                }
              },
              {
                label: this.$t('fleet/documents/prl_cargo.cost_location'),
                key: 'cost_location',
                style: {
                  width: '160px',
                  textAlign: 'center'
                }
              },
              {
                label: this.$t('fleet/documents/machine.machinist'),
                key: 'engineer_name',
                style: {
                  width: '200px',
                  textAlign: 'center'
                }
              },
              {
                label: this.$t('fleet/documents/machine.document_creation_date_time'),
                key: 'created_at',
                style: {
                  width: '200px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.status'),
                key: 'status',
                style: {
                  width: '200px',
                  textAlign: 'center'
                },
                always_visible: true
              }
            ],
            paginationConfig: {
              perPageOptions: [20, 50, 100],
              perPage: 20,
              currentPage: 1
            },
            actionsConfig: [
              {
                label: this.$t('base.edit'),
                icon: 'mdi-pencil-box',
                click: (event) => this.showMachineOperationDetails(event)
              },
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.change_status'),
                icon: 'mdi-swap-horizontal',
                visible: (event) => {
                  const { data } = event || {}
                  const { canChangeStatus } = data?.permissions || {}

                  return !!canChangeStatus
                },
                click: (event) => this.onChangeStatus(event)
              },
              {
                label: this.$t('base.delete'),
                icon: 'mdi-delete',
                visible: (event) => {
                  const { data } = event || {}
                  const { canDelete } = data?.permissions || {}

                  return !!canDelete
                },
                click: (event) => this.onDocumentDelete(event)
              }
            ],
            filtersConfig: {
              fields: [
                {
                  key: 'id',
                  label: this.$t('fleet/documents/prl_cargo.sr_no')
                },
                {
                  key: 'foreign_code',
                  label: this.$t('fleet/documents/machine.machine_operation_document_number')
                },
                {
                  key: 'date_time',
                  label: this.$t('fleet/documents/prl_cargo.date')
                },
                {
                  key: 'vehicle_brand',
                  label: this.$t('fleet/vehicle.label'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/get-vehicle',
                    custom_display_value: (value) => {
                      return value && value.vehicle_brand ? value.vehicle_brand : null
                    },
                    additionalQueryParams: {
                      by: 'registration',
                      vehicle_type: 'vehicle',
                      document_type: 'MACHINE_OPERATION'
                    },
                    form_value: 'vehicle_brand'
                  }
                },
                {
                  key: 'vehicle_garage_number',
                  label: this.$t('fleet/vehicle_attribute.garage_number'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/get-vehicle',
                    custom_display_value: (value) => {
                      return value && value.vehicle_garage_number ? value.vehicle_garage_number : null
                    },
                    additionalQueryParams: {
                      by: 'garage_number',
                      vehicle_type: 'vehicle',
                      document_type: 'MACHINE_OPERATION'
                    },
                    form_value: 'vehicle_garage_number'
                  }
                },
                {
                  key: 'construction_site',
                  label: this.$t('fleet/documents/machine.construction_site_drive')
                },
                {
                  key: 'section',
                  label: this.$t('fleet/documents/machine.section')
                },
                {
                  key: 'cost_location',
                  label: this.$t('fleet/documents/prl_cargo.cost_location')
                },
                {
                  key: 'engineer_name',
                  label: this.$t('fleet/documents/machine.machinist')
                },
                {
                  key: 'created_at',
                  label: this.$t('fleet/documents/machine.document_creation_date_time')
                },
                {
                  key: 'status',
                  label: this.$t('fleet/vehicle.status'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/statuses',
                    custom_display_value: (value) => {
                      const { description } = value || null
                      return description && typeof description === 'string' ? this.$t(description) : ''
                    },
                    form_value: 'id'
                  }
                }
              ]
            }
          }
        },
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.machine_operation'),
              bolded: true
            }
          ]
        }
      }
    },

    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'travel-documents/machine-operation',
                method: 'post'
              }
            },
            hiddenFormKeys: [
              'vehicle_id'
            ],
            additionalSetup: async (event) => await this.callBasicInfoAdditionalSetup(event),
            afterSave: (event, data) => this.redirectToEdit(event, data),
            tabContent: [
              // Document data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/machine.document_data'),
                component: columnsContentComponent,
                showButtons: true,
                fullWidth: true,
                useInitialAutocompletes: false,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        row: 1,
                        column: 1,
                        label: this.$t('fleet/documents/machine.machine_operation_document_number'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        row: 1,
                        column: 2,
                        label: this.$t('fleet/documents/prl_cargo.date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        initialValue: currentDate('DD.MM.YYYY.') + ' ' + currentTime('HH:mm'),
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        row: 1,
                        column: 3,
                        label: this.$t('fleet/vehicle.status'),
                        initialValue: 'U obradi',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'created_at',
                        row: 1,
                        column: 4,
                        initialValue: currentTime('HH:mm'),
                        label: this.$t('fleet/documents/machine.document_creation_time'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'company_name',
                        row: 2,
                        column: 1,
                        colSpan: 2,
                        label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'organizational_unit',
                        row: 2,
                        column: 3,
                        label: this.$t('fleet.organizational_unit'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'department',
                        row: 2,
                        column: 4,
                        label: this.$t('fleet.department'),
                        creatable: true,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Machine data card
              {
                subTitle: this.$t('fleet/documents/machine.machine_data'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onMachineRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_registration ? value.vehicle_registration : null
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'vehicle',
                            document_type: 'MACHINE_OPERATION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onMachineGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_garage_number ? value.vehicle_garage_number : null
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'MACHINE_OPERATION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Customer information card
              {
                subTitle: this.$t('fleet/documents/machine.customer_information'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'client_name',
                        label: this.$t('fleet/documents/machine.client_name'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'construction_site',
                        label: this.$t('fleet/documents/machine.construction_site_drive'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'section',
                        label: this.$t('fleet/documents/machine.section'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'cost_location',
                        label: this.$t('fleet/documents/prl_cargo.cost_location'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/machine.machine_operation_data'),
            disabled: true
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.machine_operation')
            },
            {
              text: this.$t('fleet/documents/machine.adding_new_machine_operation_data'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    showMachineOperationDetails (event) {
      const { data } = event || {}
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'MachineOperationDetailsView',
          params: {
            id: id
          },
          query: {
            bread_crumb_value: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        })
      }
    },

    redirectToEdit (event, data) {
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'MachineOperationDetailsView',
          params: {
            id: id,
            breadCrumbValue: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        })
      }
    },

    async onChangeStatus (event) {
      const { id } = event?.data?.status || {}
      const { id: documentId } = event?.data || {}
      const { fetchTableData } = event || null

      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'current_status', id)
        this.$set(this.changeStatusDialogConfig, 'document_id', documentId)
        if (typeof fetchTableData === 'function') {
          this.$set(this.changeStatusDialogConfig, 'callback', () => fetchTableData())
        }
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'current_status', id)
            this.$set(this.changeStatusDialogConfig, 'document_id', documentId)
            if (typeof fetchTableData === 'function') {
              this.$set(this.changeStatusDialogConfig, 'callback', () => fetchTableData())
            }
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    },

    showCreateNewMachineOperation (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage()
      }
    },

    updateFormData (updateFunction, props = {}) {
      if (updateFunction && typeof updateFunction === 'function') {
        updateFunction(props)
      }
    },

    async onMachineRegistrationSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_garage_number: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: selectedItem.organizational_unit || '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'MACHINE_OPERATION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_garage_number: selectedItem.vehicle_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onMachineGarageNumberSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_registration: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          organizational_unit: selectedItem.organizational_unit || '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'MACHINE_OPERATION'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_registration: selectedItem.vehicle_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async callBasicInfoAdditionalSetup (event) {
      const { updateData } = event || {}

      const { data: driverCompanyData } = await api().fleet.get('travel-documents/get-driver-company-from-user', {})

      if (driverCompanyData) {
        const { company } = driverCompanyData || {}

        this.updateFormData(updateData, {
          company_name: company?.name || ''
        })
      }
    },

    async onDocumentDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.delete_document_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/vehicle/documents_tab.delete_dialog_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.deleteDocumentCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async deleteDocumentCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}

        if (id) {
          await api().fleet.delete(`travel-documents/machine-operation/${id}`)

          // Close confirmation dialog
          this.$set(this.informationDialogData, 'show', false)

          // Refresh table data after successfully document delete
          if (typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    }
  }
}
</script>
