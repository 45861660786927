<template>
  <table-index
    :config="tableConfig"
  />
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'

export default {
  name: 'DocumentsForSignatureView',

  components: {
    TableIndex
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'travel-documents/signature-requested',
              method: 'get'
            }
          },
          rowClick: (event) => this.redirectToDocumentForSignature(event),
          title_and_back_config: {
            title: this.$t('fleet/documents/prl_cargo.documents_for_signature')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            cellsConfig: {
              travel_document_type: {
                custom_display_value: (data) => {
                  return data && data.travel_document_type && data.travel_document_type.description ? this.$t(data.travel_document_type.description) : '-'
                }
              },
              id: {
                style: {
                  textAlign: 'center'
                }
              },
              created_at: {
                style: {
                  textAlign: 'center'
                }
              },
              requested_person: {
                style: {
                  textAlign: 'center'
                }
              }
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.sr_no'),
                key: 'id',
                style: {
                  width: '100px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.document_type'),
                key: 'travel_document_type',
                style: {
                  width: '490px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.signature_request_date'),
                key: 'created_at',
                style: {
                  width: '490px',
                  textAlign: 'center'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.signature_requested'),
                key: 'requested_person',
                style: {
                  width: '490px',
                  textAlign: 'center'
                },
                always_visible: true
              }
            ]
          }
        },
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet/documents/prl_cargo.documents_for_signature'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    redirectToDocumentForSignature (event) {
      const { data } = event || {}
      const {
        foreign_code: foreignCode,
        travel_document_id: id,
        travel_document_type: travelDocumentType,
        signature_type: signatureType,
        item_id: itemId
      } = data || {}

      const customQuery = {
        foreign_code: '',
        travel_document_type: travelDocumentType?.name ?? '',
        item_id: itemId ?? '',
        sign_button_color: '#EAB308'
      }

      let viewName

      // Mapping view name based on travel document type
      if (travelDocumentType && travelDocumentType.name) {
        switch (travelDocumentType.name) {
          case 'PRL_CARGO':
            viewName = 'PrlCargoDetailsView'
            break
          case 'MACHINE_OPERATION':
            viewName = 'MachineOperationDetailsView'
            break
          default:
            viewName = 'DispositionDetailsView'
        }
      }

      // Choose which tab should be opened first based on provided signature type
      switch (signatureType) {
        case 'PRL_CARGO_DRIVER_MOVEMENT':
        case 'PRL_CARGO_AUDITOR_MOVEMENT':
          customQuery.open_tab_key = 'vehicle-movement'
          customQuery.sign_button_color = '#EAB308'
          break
        case 'PRL_CARGO_DEPARTURE':
          customQuery.departure_color = '#EAB308'
          customQuery.open_tab_key = 'garage'
          break
        case 'PRL_CARGO_ARRIVAL':
          customQuery.arrival_color = '#EAB308'
          customQuery.open_tab_key = 'garage'
          break
        case 'PRL_CARGO_FUEL_ISSUER':
          customQuery.open_tab_key = 'fuel'
          customQuery.sign_button_color = '#EAB308'
          break
        case 'MACHINE_OPERATION_MANAGER':
        case 'MACHINE_OPERATION_ENGINEER':
          customQuery.open_tab_key = 'machine-operation-data'
          customQuery.sign_button_color = '#EAB308'
          break
        case 'DISPOSITION_DISPOSER':
          customQuery.open_tab_key = 'vehicle-operation-data'
          customQuery.sign_button_color = '#EAB308'
          break
        case 'DISPOSITION_RECIPIENT':
          customQuery.open_tab_key = 'vehicle-operation-data'
          customQuery.sign_button_color = '#EAB308'
          break
      }

      if (viewName && id) {
        this.$router.push({
          name: viewName,
          params: {
            id: id
          },
          query: {
            ...customQuery,
            foreign_code: foreignCode ?? this.$t('fleet/documents.signing_document')
          }
        })
      }
    }
  }
}
</script>
